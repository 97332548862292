<template>
  <div>
    <b-row>
      <b-col md="12">
        <AnalyticHeader />
      </b-col>
    </b-row>
    <section>
      <listDirecionadorOs :status="status" />
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapState } from 'vuex'
import AnalyticHeader from '@/components/indicadores/header.vue'
import listDirecionadorOs from '../../../direcionador/dashboard/components/ListDirecionadorOs.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticHeader,
    listDirecionadorOs,
  },
  data() {
    return {
      status: '',
      empresaID: '',
      userId: '',
      statisticsItems: [
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '',
          subtitle: 'CMR',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-warning',
          title: '',
          subtitle: 'CMG',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: ' Dias',
          subtitle: 'TAT',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'RefreshCcwIcon',
          color: 'light-info',
          title: '%',
          subtitle: 'PT',
          customClass: '',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: '%',
          subtitle: 'REC',
          customClass: '',
        },
        {
          icon: 'CornerUpLeftIcon',
          color: 'light-info',
          title: '0 %',
          subtitle: 'LMI',
          customClass: '',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      filteredList: state => state.direcionador.filteredList,
    }),

    stataticsItemsData() {
      const { filteredList } = this

      let qtOSFinalizada = 0
      let somaQDA = 0

      let valorTotalSeguradora = 0
      let qtS6 = 0

      let qtS9 = 0
      let valorTotalSeguradora9 = 0

      let qtReclamacao = 0
      let somaIS = 0

      filteredList.forEach(o => {
        if (o.status_os_id === 6 || o.status_os_id === 7 || o.status_os_id === 9) {
          qtOSFinalizada += 1
          somaQDA += o.dias_em_aberto

          if (o.produto_valor) {
            somaIS += parseFloat(o.produto_valor)
          }
        }

        if (o.status_os_id === 6) {
          qtS6 += 1
          if (o.valor_total_seguradora) {
            valorTotalSeguradora += parseFloat(o.valor_total_seguradora)
          }
        }

        if (o.status_os_id === 9) {
          qtS9 += 1
          if (o.valor_total_seguradora) {
            valorTotalSeguradora9 += parseFloat(o.valor_total_seguradora)
          }
        }

        if (o.reclamacao === 1) {
          qtReclamacao += 1
        }
      })

      const tCMG = parseInt(qtS6) + parseInt(qtS9)
      const vCMG = parseFloat(valorTotalSeguradora) + parseFloat(valorTotalSeguradora9)

      const CMR = (valorTotalSeguradora / qtS6).toFixed(2) || 0
      const TAT = (somaQDA / qtOSFinalizada).toFixed(0) || 0
      const PT = ((qtS9 / tCMG) * 100).toFixed(2) || 0
      const CMG = (vCMG / tCMG).toFixed(2) || 0
      const REC = ((qtReclamacao / filteredList.length) * 100).toFixed(2) || 0

      const LMI = ((CMG / somaIS) * 100).toFixed(2) || 0

      this.statisticsItems[0].title = `R$ ${CMR}`
      this.statisticsItems[1].title = `R$ ${CMG}`
      this.statisticsItems[2].title = `${TAT} Dias`
      this.statisticsItems[3].title = `${PT}%`
      this.statisticsItems[4].title = `${REC}%`
      this.statisticsItems[5].title = `${LMI}%`
      return [...this.statisticsItems]
    },
  },
  created() {
    // const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    const segId = JSON.parse(localStorage.getItem('userData'))?.seguradora_id
    this.$store.dispatch('direcionador/getTotalizadoresOs', { segId })
  },
}
</script>
