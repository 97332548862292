<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Olá 🎉 - {{ userName }}!!
      </b-card-title>
      <b-dropdown
        no-caret
        right
        :text="daySelected ? daySelected.label: 'Filtro por Período'"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in periodoPreset"
          :key="day.label"
          @click="setPeriodFilter(day)"
        >
          {{ day.label }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <b-row>
        <!-- TOTAL OS COUNT -->
        <b-col
          class="d-flex flex-column flex-wrap text-center"
          sm="3"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ items.length }}
          </h1>
          <small>Ordens de Serviço</small>
          <b-button
            v-if="direcionador"
            variant="primary"
            href="/ordem_de_servico/cadastro"
            size="small"
          >
            <span class="align-middle">+ Nova OS</span>
          </b-button>
        </b-col>

        <!-- OS CHART -->
        <b-col
          v-if="loadChart && supportTrackerRadialBar.chartOptions.labels.length !== 0"
          class="d-flex justify-content-center"
          sm="9"
        >
          <!-- <h2>teste da porra</h2> -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="supportTrackerRadialBar.series"
          />
        </b-col>
      </b-row>

      <!-- OS BASIC STATUS COUNT -->
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50">
            Nova
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ totalizadores.agendar }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Em Aberto
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ calcularAberto(totalizadores) }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Acima de 30 dias
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ totalizadores.total_atrasada }}</span>
        </div>
      </div>
    </b-card-body>
    <b-modal
      v-if="periodFilter"
      ref="customPeriodFilter"
      size="sm"
      ok-title="Aplicar"
      cancel-title="Cancelar"
      cancel-variant="outline-danger"
      title="Filtro personalizado"
      no-close-on-backdrop
      @cancel="clearFilterForm"
      @ok="applyPeriodFilter"
    >
      <b-row>
        <b-col>
          <label for="">Data Inicial</label>
          <flat-pickr
            v-model="periodFilter.startDate"
            value=""
            class="form-control"
          />
        </b-col>
        <b-col>
          <label for="">Data Final</label>
          <flat-pickr
            v-model="periodFilter.endDate"
            value=""
            class="form-control"
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BButton, BDropdown, BDropdownItem } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import VueApexCharts from 'vue-apexcharts'

import { $themeColors } from '@themeConfig'

import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VueApexCharts,
    BCardText,
    BDropdown,
    BDropdownItem,
    flatPickr,
  },
  props: {
    direcionador: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadChart: false,
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: [],
        },
        series: [],
      },
      periodFilter: {
        startDate: null,
        endDate: null,
      },
      userName: '',
      daySelected: null,
    }
  },

  computed: {
    ...mapState({
      items: state => state.direcionador.filteredList,
      loadingData: state => state.direcionador.loadingData,
    }),
    ...mapGetters({
      totalizadoresArray: 'direcionador/totalizadores',
    }),

    totalizadores() {
      return this.totalizadoresArray[0] || {}
    },

    periodoPreset() {
      const fifteenDaysBeforeDate = this.getPreviousDate(25)
      const lastMonthDate = this.getPreviousDate(50)

      return [fifteenDaysBeforeDate, lastMonthDate, { label: 'Personalizado', startDate: 0, endDate: 0 }, { label: 'Todo Período' }]
    },
  },

  watch: {
    totalizadores() {
      this.supportTrackerRadialBar.chartOptions.labels = []
      this.loadChart = false
      setTimeout(() => {
        this.dadosGraficos()
      }, 1000)
    },
  },

  created() {
    this.userName = localStorage.getItem('userName')
  },

  methods: {
    calcularAberto(data) {
      const total = ['em_atendimento', 'aguardando_aprovacao', 'sem_contato', 'em_reparo', 'revisao'].reduce((acc, key) => acc + Number(data[key]), 0)
      return total
    },

    dadosGraficos() {
      const totalOs = this.totalizadores.total
      const totalFin = totalOs - this.calcularAberto(this.totalizadores)
      const total = (totalFin / totalOs) * 100
      const totalFromated = Number.isFinite(total) ? total.toFixed(1) : 0
      const totalOsLabel = `${totalFin} OS FINALIZADAS`

      this.supportTrackerRadialBar.chartOptions.labels = [totalOsLabel]
      this.supportTrackerRadialBar.series = [totalFromated]

      this.loadChart = true
    },

    getPreviousDate(daysBefore, current = new Date()) {
      const dateBefore = new Date(current.getTime())
      dateBefore.setDate(current.getDate() - daysBefore)

      const startDate = dateBefore.toISOString().slice(0, 10)
      const endDate = current.toISOString().slice(0, 10)

      return {
        startDate,
        endDate,
        label: `Últimos ${daysBefore} dias`,
      }
    },

    applyPeriodFilter() {
      const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
      const { userId } = localStorage

      if (!this.periodFilter) {
        this.$store.dispatch('direcionador/getTotalizadoresOs', {
          id: this.direcionador ? empresaID : null,
          atId: this.direcionador ? null : userId,
          query: {
            data_inicial: '2000-01-01',
            data_final: '2030-12-31',
          },
        })

        return null
      }

      const { startDate, endDate, label } = this.periodFilter

      const diffStartEndDate = this.getDateDiff(new Date(startDate), new Date(endDate))

      this.daySelected = {
        startDate,
        endDate,
        label: label === 'Personalizado' ? `${this.brazilianDate(startDate)} até ${this.brazilianDate(endDate)}` : `Últimos ${diffStartEndDate} dias`,
      }

      this.$store.dispatch('direcionador/getTotalizadoresOs', {
        id: this.direcionador ? empresaID : null,
        atId: this.direcionador ? null : userId,
        query: {
          data_inicial: startDate,
          data_final: endDate,
        },
      })

      return null
    },

    getDateDiff(startDate, endDate) {
      // Get the Timestamp
      const startDateTimestamp = startDate.getTime()
      const endDateTimestamp = endDate.getTime()

      let calc

      // Check which timestamp is greater
      if (startDateTimestamp > endDateTimestamp) {
        calc = new Date(startDateTimestamp - endDateTimestamp)
      } else {
        calc = new Date(endDateTimestamp - startDateTimestamp)
      }

      return calc / (1000 * 3600 * 24)
    },

    setPeriodFilter(item) {
      const { startDate, endDate, label } = item

      this.periodFilter = {
        startDate: null,
        endDate: null,
        label,
      }

      if (startDate === undefined && endDate === undefined) {
        this.periodFilter = null

        this.daySelected = item
        this.applyPeriodFilter()

        return null
      }

      if (startDate === 0 && endDate === 0) {
        const defaultDate = this.getPreviousDate(0)

        this.periodFilter.endDate = defaultDate.endDate

        setTimeout(() => this.$refs.customPeriodFilter.show(), 500)
        return null
      }

      this.clearFilterForm()

      this.periodFilter = {
        startDate,
        endDate,
      }

      this.daySelected = item

      this.applyPeriodFilter()

      return null
    },

    clearFilterForm() {
      this.periodFilter = {
        startDate: null,
        endDate: null,
      }
      this.daySelected = null
    },

    brazilianDate(date) {
      const newDate = date.split('-')

      return `${newDate[2]}/${newDate[1]}/${newDate[0]}`
    },
  },
}
</script>
