<template>
  <div>
    <b-row
      class="match-height"
    >
      <b-col
        md="4"
      >
        <Analytic :direcionador="direcionador" />
      </b-col>
      <b-col
        md="8"
      >
        <Indicators />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col md="12">
        <TotalServicesStatus />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapState } from 'vuex'

import Analytic from './components/Analytic.vue'
import Indicators from './components/indicators.vue'
import TotalServicesStatus from './components/TotalServicesStatus.vue'

export default {
  components: {
    BRow,
    BCol,

    Analytic,
    Indicators,
    TotalServicesStatus,
  },
  props: {
    direcionador: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      supportTracker: {
        title: 'Support Tracker',
        lastDays: ['Últimos 15 dias', 'Último mês', 'Todo Período'],
        totalTicket: 163,
        newTicket: 29,
        openTicket: 63,
        responseTime: 1,
        supportTrackerRadialBar: {
          series: [83],
        },
      },
      status: '',
      empresaID: '',
      userId: '',
    }
  },
  computed: {
    ...mapState({
      filteredList: state => state.direcionador.filteredList,
    }),
  },
  async mounted() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
  },
  methods: {
    atualizarStatus(status) {
      this.status = status
    },
  },
}
</script>
