<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Indicadores</b-card-title>
    </b-card-header>

    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          xl="3"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside
              style="margin-bottom: 37px; margin-top: 37px"
              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
} from 'bootstrap-vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      statisticsItems: 'direcionador/indicatorsData',
    }),
  },
  watch: {},
}
</script>
