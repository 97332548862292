<template>
  <b-row class="match-height">
    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('AGENDAR')"
    >
      <statistic-card-vertical
        color="warning"
        icon="CalendarIcon"
        :statistic="totalizadores[0].agendar"
        statistic-title="AGENDAR"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('SEM CONTATO')"
    >
      <statistic-card-vertical
        color="secondary"
        icon="CalendarIcon"
        :statistic="totalizadores[0].sem_contato"
        statistic-title="Sem Contato"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('EM ATENDIMENTO')"
    >
      <statistic-card-vertical
        color="warning"
        icon="MessageSquareIcon"
        :statistic="totalizadores[0].em_atendimento"
        statistic-title="Em Atendimento"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('REVISÃO')"
    >
      <statistic-card-vertical
        color="danger"
        icon="AlertTriangleIcon"
        :statistic="totalizadores[0].revisao"
        statistic-title="Revisão"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('LAUDO')"
    >
      <statistic-card-vertical
        color="warning"
        icon="ClipboardIcon"
        :statistic="totalizadores[0].laudo"
        statistic-title="Laudo"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('AGUARDANDO APROVAÇÃO')"
    >
      <statistic-card-vertical
        color="dark"
        icon="ShoppingBagIcon"
        :statistic="totalizadores[0].aguardando_aprovacao"
        statistic-title="Aguardando Aprovação"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('EM REPARO')"
    >
      <statistic-card-vertical
        color="secondary"
        icon="ToolIcon"
        :statistic="totalizadores[0].em_reparo"
        statistic-title="Em Reparo"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('REPARO CONCLUIDO')"
    >
      <statistic-card-vertical
        color="success"
        icon="AwardIcon"
        :statistic="totalizadores[0].reparo_concluido"
        statistic-title="Reparo Concluído"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('REPARO ENTREGUE')"
    >
      <statistic-card-vertical
        color="info"
        icon="TruckIcon"
        :statistic="totalizadores[0].reparo_entregue"
        statistic-title="Reparo Entregue"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('TROCADO')"
    >
      <statistic-card-vertical
        color="danger"
        icon="RefreshCcwIcon"
        :statistic="totalizadores[0].troca"
        statistic-title="Trocado"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('NEGADO')"
    >
      <statistic-card-vertical
        color="warning"
        icon="ThumbsDownIcon"
        :statistic="totalizadores[0].negado"
        statistic-title="Negado"
        class="card-totais-atendimento-status"
      />
    </b-col>

    <b-col
      xl="2"
      md="2"
      sm="6"
      @click="enviarStatus('CANCELADO')"
    >
      <statistic-card-vertical
        color="danger"
        icon="XCircleIcon"
        :statistic="totalizadores[0].cancelado"
        statistic-title="Cancelado"
        class="card-totais-atendimento-status"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      totalizadores: 'direcionador/totalizadores',
    }),
  },

  methods: {
    enviarStatus(status) {
      this.$store.commit('direcionador/SET_STATUS_CLICK', status)
    },
  },
}
</script>

<style>
.card-totais-atendimento-status:hover {
  background-color: #1E90FF;
  cursor: pointer;
}
</style>
